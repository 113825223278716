import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { message } from "antd";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    typeLoad: [],
    dealers: [],
    status: "",
    message: "",
    fileUploadSummary: [],
  },
};

const slice = createSlice({
  name: "fileupload",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    dealer(state, action) {
      state.loading = false;
      state.filters_data.dealers = action.payload.dealers.map((x) => ({
        ...x,
        key: x.id,
      }));
    },
    clearStagingData(state) {
      state.data.fileUploadSummary = [];
    },
    typeLoad(state, action) {
      state.loading = false;
      state.filters_data.typeLoad = action.payload.typeLoad.map((x) => ({
        ...x,
        key: x.id,
      }));
    },
    setFileUploadSummaryData(state, action) {
      state.data.fileUploadSummary = action.payload;
      state.loading = false;
    },
    uploadSuccess(state, action) {
      state.loading = false;
      state.data.status = "ok";
      state.data.message = action.payload.message;
    },
    resetUploadStatus(state) {
      state.data.status = "";
      state.data.message = "";
    },
    downloadStart(state) {
      state.loading = true;
    },
    downloadError(state, action) {
      state.loading = false;
      state.error = action.payload;
      message.error(action.payload);
    },
    downloadSuccess(state) {
      state.loading = false;
      message.success("Descarga completada con éxito");
    },
  },
});

export const {
  startLoading,
  hasError,
  dealer,
  typeLoad,
  uploadSuccess,
  setFileUploadSummaryData,
  clearStagingData,
  resetUploadStatus,
  downloadStart,
  downloadError,
  downloadSuccess,
} = slice.actions;
export default slice.reducer;

export function getDealers(filters) {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/fileUpload/dealers/list`, {
        params: filters,
      });
      dispatch(dealer(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };
}

export function getTypeLoadList() {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.get("/fileUpload/typeLoad/list");
      dispatch(typeLoad(response.data));
    } catch (error) {
      dispatch(hasError(error.toString()));
    }
  };
}

export function uploadFileExcel(formData, id_dealer, tipo_evento, userId) {
  formData.append("id_dealer", id_dealer);
  formData.append("tipo_evento", tipo_evento);
  formData.append("userId", userId);

  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await axios.post("/accessFiles/Excel", formData);
      dispatch(uploadSuccess({ message: "Archivo subido con éxito" }));
      message.success("Archivo subido con éxito", 2);
    } catch (error) {
      console.error("Error en la carga:", error);
      if (error.response && error.response.data) {
        dispatch(hasError(error.response.data.error));
        message.error(error.response.data.error, 2);
      } else if (error.request) {
        dispatch(hasError("No se recibió respuesta del servidor."));
        message.error("No se recibió respuesta del servidor", 2);
      } else {
        dispatch(hasError("Error al enviar la solicitud."));
        message.error("Error al enviar la solicitud", 2);
      }
    }
  };
}

export function fetchFileUploadSummary() {
  return async (dispatch) => {
    dispatch(clearStagingData());
    dispatch(startLoading());
    try {
      const params = new URLSearchParams();
      const response = await axios.get(`/fetchFileUploadSummary${params.toString()}`);
      dispatch(setFileUploadSummaryData(response.data));
    } catch (error) {
      console.error("Error fetching File Upload Summary data:", error);
      dispatch(hasError("Error al obtener datos de File Upload Summary"));
    }
  };
}

export function downloadFileExcel(filename) {
  return async (dispatch) => {
    dispatch(downloadStart());
    try {
      const response = await axios.get(`/download/${filename}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch(downloadSuccess());
    } catch (error) {
      console.error("Error en la descarga:", error);
      dispatch(downloadError("Error al descargar el archivo."));
    }
  };
}

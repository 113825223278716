import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axiosInstance from "./axios";
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = new Date().getTime() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken, tokenRefresh) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("tokenRefresh", tokenRefresh);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenRefresh");
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };

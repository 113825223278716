import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { access = [], isAuthenticated } = useAuth();

  if (isAuthenticated) {
    if (access.includes(13)) {
      return <Navigate to="/estado-cargas" replace />;
    }
    else if (access.includes(2)) {
      return <Navigate to="/ventas-charts" replace />;
    } else if (access.includes(3)) {
      return <Navigate to="/ventas" replace />;
    } else if (access.includes(5)) {
      return <Navigate to="/ot-charts" replace />;
    } else if (access.includes(6)) {
      return <Navigate to="/ot" replace />;
    } else if (access.includes(7)) {
      return <Navigate to="/ot-osat-report" replace />;
    } else if (access.includes(10)) {
      return <Navigate to="/repuestos-charts" replace />;
    } else if (access.includes(16)) {
      return <Navigate to="/repuestos" replace />;
    } else if (access.includes(11)) {
      return <Navigate to="/stock" replace />;
    } else if (access.includes(15)) {
      return <Navigate to="/conexion-usuarios" replace />;
    } else if (access.includes(12)) {
      return <Navigate to="/users" replace />;
    } else if (access.includes(18)) {
      return <Navigate to="/profiles" replace />;
    } else if (access.includes(19)) {
      return <Navigate to="/brands" replace />;
    } else if (access.includes(20)) {
      return <Navigate to="/dealers" replace />;
    } else if (access.includes(21)) {
      return <Navigate to="/notifications" replace />;
    }
  }

  return <>{children}</>;
}

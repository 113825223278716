import React from "react";
import { Layout, Menu } from "antd";
import {
  SolutionOutlined,
  ProfileOutlined,
  ToolOutlined,
  FolderOutlined,
  PieChartOutlined,
  DatabaseOutlined,
  CarOutlined,
  TableOutlined,
  UserOutlined,
  TagOutlined,
  ShopOutlined,
  HomeOutlined,
  NotificationOutlined,
  SettingOutlined,
  UploadOutlined,
  CloudUploadOutlined,
  UnorderedListOutlined,
  LoginOutlined
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const SiderMenu = (props) => {
  const { access } = useAuth();
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const { collapsed } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getMenuKey = (pathname) => {
    switch (pathname) {
      case "/estado-cargas":
        return "13";
      case "/ventas-charts":
        return "0";
      case "/ventas":
        return "1";
      case "/stock":
        return "6";
      case "/ot-charts":
        return "2";
      case "/ot":
        return "3";
      case "/repuestos":
        return "5";
      case "/repuestos-charts":
        return "18";
      case "/file-upload":
        return "14";
      case "/conexion-usuarios":
        return "15";
      case "/users":
        return "8";
      default:
        return "13";
    }
  };


  return (
    <Sider
      width={230}
      trigger={null}
      collapsible
      collapsed={collapsed}
      className="site-layout-background"
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[getMenuKey(pathname)]}
        theme="dark"
        style={{ height: "100%", borderRight: 0, backgroundColor: "#0e294b" }}
      >
        {access.includes(13) ? (
          <SubMenu key="home" icon={<HomeOutlined />} title="Inicio">
            {access.includes(0) ? (
              <Menu.Item
              key="13"
              icon={<NotificationOutlined />}
              onClick={() => navigate("/estado-cargas")}
            >
              Estado de cargas
            </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {access.includes(1) ? (
          <SubMenu key="sub1" icon={<CarOutlined />} title="Venta Vehículo">
            {access.includes(2) ? (
              <Menu.Item
                key="0"
                icon={<PieChartOutlined />}
                onClick={() => navigate("/ventas-charts")}
              >
                Inicio
              </Menu.Item>
            ) : (
              ""
            )}
            {access.includes(3) ? (
              <Menu.Item
                key="1"
                icon={<SolutionOutlined />}
                onClick={() => navigate("/ventas")}
              >
                Datos
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {access.includes(4) ? (
          <SubMenu key="sub2" icon={<FolderOutlined />} title="OTs">
            {access.includes(5) ? (
              <Menu.Item
                key="2"
                icon={<PieChartOutlined />}
                onClick={() => navigate("/ot-charts")}
              >
                Inicio
              </Menu.Item>
            ) : (
              ""
            )}
            {access.includes(6) ? (
              <Menu.Item
                key="3"
                icon={<SolutionOutlined />}
                onClick={() => navigate("/ot")}
              >
                Datos
              </Menu.Item>
            ) : (
              ""
            )}
            {access.includes(7) ? (
              <Menu.Item
                key="4"
                icon={<TableOutlined />}
                onClick={() => navigate("/ot-osat-report")}
              >
                Reporte OSAT
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {access.includes(8) ? (
          <SubMenu key="sub3" icon={<ToolOutlined />} title="Venta Repuestos">
            {access.includes(9) ? (
              <Menu.Item
                key="18"
                icon={<PieChartOutlined />}
                onClick={() => navigate("/repuestos-charts")}
              >
                Inicio
              </Menu.Item>
            ) : (
              ""
            )}
            {access.includes(10) ? (
              <Menu.Item
                key="6"
                icon={<SolutionOutlined />}
                onClick={() => navigate("/repuestos")}
              >
                Datos
              </Menu.Item>
            ) : (
              ""
            )}
            {access.includes(11) ? (
              <Menu.Item
                key="7"
                icon={<DatabaseOutlined />}
                onClick={() => navigate("/stock")}
              >
                Stock
              </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {access.includes(14) ? (
          <SubMenu key="sub5" icon={<UploadOutlined />} title="Cargas">
            {access.includes(22) ? (
              <Menu.Item
              key="14"
              icon={<CloudUploadOutlined />}
              onClick={() => navigate("/file-upload")}
            >
              Subir Archivo
            </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
        {access.includes(15) ? (
          <SubMenu key="sub6" icon={<UnorderedListOutlined />} title="Bitácora">
            {access.includes(17) ? (
              <Menu.Item
              key="15"
              icon={<LoginOutlined />}
              onClick={() => navigate("/conexion-usuarios")}
            >
              Conexión de usuarios
            </Menu.Item>
            ) : (""
            )}
          </SubMenu>) : (
          ""
        )}
        {access.includes(16) ? (
          <SubMenu key="sub4" icon={<SettingOutlined />} title="Mantenimiento">
            {access.includes(12) ? (
              <Menu.Item
              key="8"
              icon={<UserOutlined />}
              onClick={() => navigate("/users")}
            >
              Usuarios
            </Menu.Item>
            ) : (
              ""
            )}

            {access.includes(18) ? (
              <Menu.Item
              key="9"
              icon={<ProfileOutlined />}
              onClick={() => navigate("/profiles")}
            >
              Perfiles
            </Menu.Item>
            ) : (
              ""
            )}

            {access.includes(19) ? (
              <Menu.Item
              key="10"
              icon={<TagOutlined />}
              onClick={() => navigate("/brands")}
            >
              Marcas
            </Menu.Item>
            ) : (
              ""
            )}

            {access.includes(20) ? (
              <Menu.Item
              key="11"
              icon={<ShopOutlined />}
              onClick={() => navigate("/dealers")}
            >
              Concesionarios
            </Menu.Item>
            ) : (
              ""
            )}

            {access.includes(21) ? (
              <Menu.Item
              key="12"
              icon={<NotificationOutlined />}
              onClick={() => navigate("/notifications")}
            >
              Notificaciones
            </Menu.Item>
            ) : (
              ""
            )}
          </SubMenu>
        ) : (
          ""
        )}
      </Menu>
    </Sider>
  );
};

export default SiderMenu;

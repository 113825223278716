import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    loadingImporters: false,
    error: false,
    data: {
        importers: [],
        total: 0,
    },
};

const slice = createSlice({
    name: "importers",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.loadingImporters = true;
        },
        hasError(state, action) {
            state.loadingImporters = false;
            state.error = action.payload;
        },
        importers(state, action) {
            state.loadingImporters = false;
            state.data = {
                ...action.payload,
                importers: action.payload.importers.map((x) => {
                    return { ...x, key: x.id };
                }),
                total: action.payload.total,
            };
        }
    },
});

export const { startLoading, hasError, importers } = slice.actions;

export function getImporters(){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get("/importers/all");
            dispatch(slice.actions.importers(response.data));
        } catch(error){
            dispatch(slice.actions.hasError(error));
        }
    };
}

export default slice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    loading: false,
    error: false,
    data: {
        update: [],
        total: 0,
    },
};

const slice = createSlice({
    name: "updates",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        updates(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                update: action.payload
                    ? action.payload.map((x) => ({
                        ...x,
                        key: x.id
                    }))
                    : [],
                total: action.payload.length || 0,
            };
        },
        report(state) {
            state.loading = false;
            state.report = true;
        },
    },
});

export const { startLoading, hasError, updates, report } = slice.actions;

export function getUpdates() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get("/updates/all");
            dispatch(slice.actions.updates(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export default slice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  notifications: {},
  notificationsTable: {},
  page: 1
};

const notificationsSlice = createSlice({
  name: "notificationsNormalizada",
  initialState,
  reducers: {
      startLoading(state) {
        state.loading = true;
        state.error = false;
      },
      hasError(state, action) {
        state.loading = false;
        state.error = action.payload;
      },
      setNotifications(state, action) {
        state.loading = false;
        state.notifications = {
          ...action.payload,
          notifications: action.payload.notifications.map((x) => {
            return { ...x, key: x.id };
          }),
          total: action.payload.total,
        };
      },
      setNotificationsTable(state, action) {
        state.loading = false;
        state.notificationsTable = {
          ...action.payload,
          notifications: action.payload.notifications.map((x) => {
            return { ...x, key: x.id };
          }),
          total: action.payload.total,
          page: action.payload.page
        };
      }
  },
});

export const { startLoading, hasError, setNotifications, setNotificationsTable } = notificationsSlice.actions;


export const getNotifications = ({userIds, page= 1, count= 10}) => async (dispatch) => {
  try {
    const response = await axios.get(`/notification/${userIds}?page=${page}&count=${count}`);
    dispatch(setNotifications(response.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const markNotificationAsRead = (id, userId,pagetable= 1, page = 1, count = 10) => async (dispatch) => {
  try {
    await axios.put(`/notification/read/${id}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseTable = await axios.get(`/notification/${userId}?page=${pagetable}&count=${count}`);
    dispatch(setNotifications(response.data));
    dispatch(setNotificationsTable(responseTable.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const markAllNotificationsAsRead = (userId, pageTable = 1, page= 1, count= 10) => async (dispatch) => {
  try {
    await axios.put(`/notification/readAll/${userId}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseTable = await axios.get(`/notification/${userId}?page=${pageTable}&count=${count}`);
    dispatch(setNotifications(response.data));
    dispatch(setNotificationsTable(responseTable.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
}

export const deleteNotification = (id, userId, pageTable= 1, page = 1, count = 10) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.delete(`/notification/delete/${id}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseTable = await axios.get(`/notification/${userId}?page=${pageTable}&count=${count}`);
    dispatch(setNotifications(response.data));
    dispatch(setNotificationsTable(responseTable.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const deleteAllNotifications = (userId, pageTable= 1, page = 1, count = 10) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.delete(`/notification/deleteAll/${userId}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseTable = await axios.get(`/notification/${userId}?page=${pageTable}&count=${count}`);
    dispatch(setNotifications(response.data));
    dispatch(setNotificationsTable(responseTable.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
}

export const getNotificationsTable = ({userIds, page= 1, count= 10}) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.get(`/notification/${userIds}?page=${page}&count=${count}`);
    const response = await axios.get(`/notification/${userIds}?page=${page}&count=${count}`);
    dispatch(setNotificationsTable({...response.data, page}));
  } catch(error) {
    dispatch(hasError(error.toString()));
  }
}

export const markNotificationAsReadTable = (id, userId, page = 1, count = 10) => async (dispatch) => {
  try {
    await axios.put(`/notification/read/${id}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseCard = await axios.get(`/notification/${userId}?page=1&count=${count}`);
    dispatch(setNotificationsTable(response.data));
    dispatch(setNotifications(responseCard.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
}

export const markAllNotificationsAsReadTable = (userId, page= 1, count= 10) => async (dispatch) => {
  try {
    await axios.put(`/notification/readAll/${userId}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseCard = await axios.get(`/notification/${userId}?page=1&count=${count}`);
    dispatch(setNotificationsTable(response.data));
    dispatch(setNotifications(responseCard.data));
  } catch (error) {
    dispatch(hasError(error.toString()));
  }
}

export const deleteNotificationTable = (id, userId, page = 1, count = 10) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.delete(`/notification/delete/${id}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseCard = await axios.get(`/notification/${userId}?page=1&count=${count}`);
    dispatch(setNotificationsTable(response.data));
    dispatch(setNotifications(responseCard.data));
  }
  catch (error) {
    dispatch(hasError(error.toString()));
  }
};

export const deleteAllNotificationsTable = (userId, page = 1, count = 10) => async (dispatch) => {
  try {
    dispatch(startLoading());
    await axios.delete(`/notification/deleteAll/${userId}`);
    const response = await axios.get(`/notification/${userId}?page=${page}&count=${count}`);
    const responseCard = await axios.get(`/notification/${userId}?page=1&count=${count}`);
    dispatch(setNotificationsTable(response.data));
    dispatch(setNotifications(responseCard.data));
  } catch(error) {
    dispatch(hasError(error.toString()));
  }
};


export default notificationsSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    users: [],
    status: "",
    message: "",
  },
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    users(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        users: action.payload.users.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    profiles(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        profiles: action.payload.profiles.map((x) => {
          return { ...x, key: x.id };
        }),
      };
    },
    profilePermissions(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        profilePermissions: action.payload.permissions.map((x) => {
          return { ...x, key: x.id }
        })
      };
    },
    saveUser(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.data.status,
        message: action.payload.data.message,
      };
    },
    resetMessage(state, action) {
      state.data = {
        ...state.data,
        status: "",
        message: "",
      };
    },
    updateUserSuccess(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.status,
        message: action.payload.message,
      };
    },
  },
});

export default slice.reducer;

export function getUsers({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/all?`;
      let count = 0;
      for (const property in filters) {
        if(filters[property] !== null){
          url +=
          count == 0
            ? `${property}=${filters[property]}`
            : `&${property}=${filters[property]}`;
        count++;
        }
      }
      const response = await axios.get(url);
      dispatch(slice.actions.users(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfilesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/profiles?`;
      const response = await axios.get(url);
      dispatch(slice.actions.profiles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfilePermissionsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/profilepermissions`;
      const response = await axios.get(url);
      dispatch(slice.actions.profilePermissions(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/save`;
      const response = await axios.post(url, {
        ...user,
        swagger_access: user.swagger_access || false,
      });
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      if (response && response.data) {
        return Promise.resolve(response.data);
      }
      else {
        throw Promise.reject(new Error('Invalid response format'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/update`;
      const response = await axios.put(url, {
        ...user,
        swagger_access: user.swagger_access,
      });
      //dispatch(slice.actions.saveUser(response));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      if (response && response.data) {
        return Promise.resolve(response.data);
      }
      else {
        throw Promise.reject(new Error('Invalid response format'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePassAndName({ name, currentPassword, newPassword }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error("Token no encontrado");
      }
      const response = await axios.put(
        '/users/updatePassAndName',
        { name, currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      dispatch(slice.actions.updateUserSuccess(response.data));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject(error);
    }
  };
}
import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "../../utils/axios";

const initialState = {
    loading: false,
    error: false,
    filters_data: {},
    data: {
        userSession:[],
        total: 0,
    },
};

const slice = createSlice({
    name: "userSession",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        userSessions(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                userSession: action.payload.userSessions.map((x) => {
                    return { ...x, key: x.id };
                }),
                total: action.payload.total,
            };
        },
        report(state) {
            state.loading = false;
            state.report = true;
        }
    },
});

export const { startLoading, hasError, userSessions, report } = slice.actions;

export function getuserSessions({ page= 1, count= 10, filters= {} }){
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/usersession/all?page=${page}&count=${count}`;
            for(const property in filters) {
                url += `&${property}=${filters[property]}`;
            }
            const response = await axios.get(url);
            dispatch(slice.actions.userSessions(response.data));
        } catch(error){
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getReport({filters = {} }) {
    return async(dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/usersession/report?`;
            let count = 0;
            for (const key in filters){
                url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
                count++;
            }
            const result = await axios.get(url, {responseType: 'blob'});
            fileDownload(result.data, `userSession_${new Date().getTime()}.xlsx`);
            dispatch(slice.actions.report());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getOneUserSession(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/usersession/${id}`);
            dispatch(slice.actions.userSessions(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export default slice.reducer;
import React from "react";
import { Layout } from "antd";
import packageJson from "../../../package.json";

const Footer = () => {
  const { Footer } = Layout;
  return (
    <Footer style={{ textAlign: "center" }}>
      Powered by Crossover ©{new Date().getFullYear()} Versión {packageJson.version}
    </Footer>
  );
};

export default Footer;

import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {},
  parametros: {},
  dashboardData: {},
};

const slice = createSlice({
  name: "repuestos",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    repuestos(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        repuestos: action.payload.repuestos.map((x) => {
          return { ...x, key: x.id };
        }),
      };
    },
    report(state) {
      state.loading = false;
      state.report = true;
    },
    branchoffice(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        branchoffices: action.payload.branchoffices.map((x) => {
          return { ...x, key: x.branchoffice };
        }),
      };
    },
    dealer(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        dealers: action.payload.dealers.map((x) => {
          return { ...x, key: x.dealer };
        }),
      };
    },
    salesChannel(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        salesChannels: action.payload.salesChannels.map((x) => {
          return { ...x, key: x.channel };
        }),
      };
    },
    brand(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        brandsF: action.payload.brandsF.map((x) => {
          return { ...x, id_marca: x.id_marca, key: x.value };
        }),
      };
    },
    documentTypes(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        documentTypesF: action.payload.documentTypesF.map((x) => {
          return { ...x, code: x.codigo, key: x.codigo };
        }),
      };
    },
    setParametros(state, action) {
      state.loading = false;
      state.parametros[action.payload.categoria] = action.payload.parametros;
    },
    setDashboardData(state, action) {
      state.loading = false;
      state.dashboardData = action.payload.dashboard;
    },
  },
});

export default slice.reducer;

export function getRepuestos({ page = 1, count = 10, filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos?page=${page}&count=${count}`;
      for (const property in filters) {
        url += `&${property}=${filters[property]}`;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.repuestos(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReport({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/report?`;
      let count = 0;
      for (const key in filters) {
        url +=
          count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
        count++;
      }
      const result = await axios.get(url, { responseType: "blob" });
      fileDownload(result.data, `repuestos_${new Date().getTime()}.xlsx`);
      dispatch(slice.actions.report());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBranchOffices({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/branchoffices?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.branchoffice(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDealers({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/dealers?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.dealer(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSalesChannel({ dealer = '', brands = '', dealersO = '' }) {
  const filters = { dealer, brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/saleschannels?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.salesChannel(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBrands({ brands = '', dealersO = '' }) {
  const filters = { brands, dealersO }
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/brands?`;
      let count = 0;
      for (const key in filters) {
        url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`
        count++;
      }
      const response = await axios.get(url);
      dispatch(slice.actions.brand(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDocumentTypesVW() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/documentTypesVW`;
      const response = await axios.get(url);
      dispatch(slice.actions.documentTypes(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getParametros(categoria) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/repuestos/parametros`);
      dispatch(slice.actions.setParametros({ categoria, parametros: response.data }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDashboardData(filters = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/repuestos/dashboard?`;
      let count = 0;
      for (const key in filters) {
        if (filters[key]) {
          url += count === 0 ? `${key}=${filters[key]}` : `&${key}=${filters[key]}`;
          count++;
        }
      }
      const response = await axios.get(url);
      dispatch(slice.actions.setDashboardData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

import axios from 'axios';
import { config } from '../config/config';

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {

    if (error.response && error.response.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
import React, { useState } from "react";
import { Layout as LayoutAntd, Breadcrumb, ConfigProvider } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import Header from "./Header";
import SiderMenu from "./SiderMenu";
import Footer from "./Footer";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Outlet } from "react-router-dom";
import locale from "antd/lib/locale/es_ES";

const Layout = ({ children }) => {
  const { Content } = LayoutAntd;
  let url = window.location.pathname;
  let result = url.split("/");
  const isMobile = useMediaQuery("max-width", 790);
  const [collapsed, setCollapsed] = useState(false);

  const setTrueCollapsed = () => {
    setCollapsed(true);
  };

  const setFalseCollapsed = () => {
    setCollapsed(false);
  };

  return (
    <>
      <LayoutAntd className="layout">
        <Header></Header>
        <LayoutAntd>
          <SiderMenu collapsed={collapsed} />
          <LayoutAntd style={{ padding: "0 24px 24px", minHeight: "calc(100vh - 70px)" }}>
            <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>{collapsed ? (
                <MenuUnfoldOutlined
                  onClick={setFalseCollapsed}
                  style={{
                    paddingRight: "20px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <MenuFoldOutlined
                  onClick={setTrueCollapsed}
                  style={{
                    paddingRight: "20px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              )}</Breadcrumb.Item>
              <Breadcrumb.Item>{result[1].toUpperCase()}</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              className="site-layout-background"
              style={{
                padding: 24,
                margin: 0,
                minHeight: 444,
                background: "#fff",
              }}
            >
              <ConfigProvider locale={locale}>
                <Outlet />
              </ConfigProvider>
            </Content>
            {!isMobile && <Footer />}
          </LayoutAntd>
        </LayoutAntd>
      </LayoutAntd>
    </>
  );
};

export default Layout;

import React from 'react';
import './App.css';
import Router from './routes';
const App = () => (
  <div>
     <Router />
  </div>
  
);

export default App;
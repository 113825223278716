
import useAuth from "../../hooks/useAuth";
import axiosInstance from "../../utils/axios";
import { setSession } from "../../utils/jwt";

const WithAxios = ({ children }) => {
  const { logout } = useAuth();
  
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig?.url !== "/login" &&
        originalConfig?.url !== "/refresh" &&
        err.response
      ) {
        if (
          err.response.status === 500 &&
          err.response?.data?.error === "jwt expired" &&
          !originalConfig._retry
        ) {
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/refresh", {
              tokenRefresh: localStorage.getItem("tokenRefresh"),
            });
            const { token, tokenRefresh } = rs.data;
            axiosInstance.defaults.headers["Authorization"] = `Bearer ${token}`;
            originalConfig.headers["Authorization"] = `Bearer ${token}`;
            setSession(token, tokenRefresh);
            return axiosInstance(originalConfig);
          } catch (_error) {
            await logout();
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(
        (err.response && err.response.data ) || (err.error || err) || "Algo salió mal"
      );
    }
  );

  return children;
};

export default WithAxios;

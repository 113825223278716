import React, { useState } from "react";
import { Layout, Menu, Modal, Form, Input, Button, message, Divider, Tooltip, Row, Col } from "antd";
import { UserOutlined, LockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch } from 'react-redux';
import { updatePassAndName } from '../../redux/slices/users';
import mainLogo from "../../assets/logo/icononly_transparent_nobuffer.png";
import useAuth from "../../hooks/useAuth";
import { ButtonNotification } from "../notificationsNormalize/ButtonNotification";
import { ButtonUpdates } from "../updatesComponent/ButtonUpdate";

const Header = (props) => {
  const { user, logout, updateUserContext } = useAuth();
  const { Header } = Layout;
  const { SubMenu } = Menu;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      if (values.newPassword !== values.confirmPassword) {
        message.error('Las nuevas contraseñas no coinciden');
        setLoading(false);
        return;
      }
      await dispatch(updatePassAndName(values));
      updateUserContext({ ...user, name: values.name });
      message.success('Usuario actualizado correctamente');
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error(error.message || 'Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Header style={{ height: "70px", padding: "0 60px" }}>
      <div
        className="logo"
        style={{ float: "left", width: "100px", height: "115px" }}
      >
        <img
          src={mainLogo}
          style={{
            width: "60px",
            height: "65px",
            padding: "2px",
          }}
          alt="logo"
        />
      </div>
      <Menu
        mode="horizontal"
        theme="dark"
        style={{
          float: "right",
          marginTop: "3px",
          borderRadius: "10px",
          height: "40px",
        }}
      >
        <ButtonUpdates />
        <ButtonNotification user={user} />
        <SubMenu
          key="submenu"
          icon={<UserOutlined />}
          title={user && user.name}
          style={{ marginTop: "0px", minWidth: "150px" }}
        >
          <Menu.Item key="setting:7" onClick={showModal}>
            Editar Perfil
          </Menu.Item>
          <Menu.Item key="setting:8" onClick={logout}>
            Salir
          </Menu.Item>
        </SubMenu>
      </Menu>

      <Modal
        title="Editar Perfil"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        centered
        footer={[
          <Button key="cancel" onClick={handleCancel} style={{ borderRadius: '20px', transition: 'background-color 0.3s, transform 0.3s' }}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleOk} style={{ borderRadius: '20px', transition: 'background-color 0.3s, transform 0.3s' }}>
            Guardar
          </Button>,
        ]}
        bodyStyle={{ borderRadius: '20px', padding: '20px' }}
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          transition: 'transform 0.3s, box-shadow 0.3s',
          width: '300px'
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.transform = 'translateY(-5px)';
          e.currentTarget.style.boxShadow = '0 6px 12px 0 rgba(0, 0, 0, 0.3), 0 8px 24px 0 rgba(0, 0, 0, 0.22)';
        }}
        onMouseLeave={(e) => {
          e.currentTarget.style.transform = 'translateY(0)';
          e.currentTarget.style.boxShadow = '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)';
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: user.name }}
          style={{ maxWidth: '100%', margin: '0 auto' }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Nombre Completo"
                rules={[{ required: true, message: 'Por favor ingrese su nombre completo' }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'box-shadow 0.3s',
                    width: '100%'
                  }}
                  onFocus={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(24, 144, 255, 0.6)'}
                  onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="currentPassword"
                label="Contraseña Actual"
                rules={[{ required: true, message: 'Por favor ingrese su contraseña actual' }]}
                tooltip={{ title: 'Ingrese su contraseña actual para verificar su identidad', icon: <InfoCircleOutlined /> }}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'box-shadow 0.3s',
                    width: '100%'
                  }}
                  onFocus={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(24, 144, 255, 0.6)'}
                  onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="newPassword"
                label="Nueva Contraseña"
                rules={[{ required: true, message: 'Por favor ingrese su nueva contraseña' }]}
                tooltip={{ title: 'Ingrese una nueva contraseña segura', icon: <InfoCircleOutlined /> }}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'box-shadow 0.3s',
                    width: '100%'
                  }}
                  onFocus={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(24, 144, 255, 0.6)'}
                  onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="confirmPassword"
                label="Confirmar Nueva Contraseña"
                rules={[{ required: true, message: 'Por favor confirme su nueva contraseña' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  style={{
                    borderRadius: '20px',
                    transition: 'box-shadow 0.3s',
                    width: '100%'
                  }}
                  onFocus={(e) => e.currentTarget.style.boxShadow = '0 0 10px rgba(24, 144, 255, 0.6)'}
                  onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Header>
  );
};

export default Header;

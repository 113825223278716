import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: null,
  ventas: [],
  ots: [],
  repuestos: [],
  stockRepuestos: [],
  perfilConfig: null,
};

const monitoreoCargasSlice = createSlice({
  name: "monitoreoCargas",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setVentas(state, action) {
      state.ventas = action.payload;
    },
    setOts(state, action) {
      state.ots = action.payload;
    },
    setRepuestos(state, action) {
      state.repuestos = action.payload;
    },
    setStockRepuestos(state, action) {
      state.stockRepuestos = action.payload;
    },
    setPerfilConfig(state, action) {
      state.perfilConfig = action.payload;
    },
    finishLoading(state) {
      state.loading = false;
    },
  },
});

export const {
  startLoading,
  hasError,
  setVentas,
  setOts,
  setRepuestos,
  setStockRepuestos,
  setPerfilConfig,
  finishLoading,
} = monitoreoCargasSlice.actions;

export default monitoreoCargasSlice.reducer;

export const fetchCargas = (user) => async (dispatch) => {
  dispatch(startLoading());
  try {
    if (!user || !user.profileId) {
      throw new Error("Usuario no autenticado o perfil no disponible.");
    }

    const perfilConfigResponse = await axios.get(
      `/admin/profile/${user.profileId}/settings`
    );
    if (perfilConfigResponse.status !== 200 || !perfilConfigResponse.data) {
      throw new Error("Error al obtener la configuración del perfil");
    }
    const perfilConfig = perfilConfigResponse.data;

    if (!perfilConfig.brands || !perfilConfig.dealers) {
      throw new Error(
        "La configuración del perfil es inválida o está incompleta."
      );
    }

    dispatch(setPerfilConfig(perfilConfig));

    const marcasHabilitadas = new Set(
      perfilConfig.brands
        .filter((brand) => brand.settingEnabled)
        .map((brand) => brand.brandID)
    );
    const dealersHabilitados = new Set(
      perfilConfig.dealers
        .filter((dealer) => dealer.settingEnabled)
        .map((dealer) => dealer.dealerID)
    );

    const response = await axios.get(`/alertas/cargas`);
    if (response.status !== 200 || !Array.isArray(response.data)) {
      throw new Error("La respuesta de la API de cargas es inválida.");
    }
    let data = response.data;

    data = data.filter(
      (carga) =>
        marcasHabilitadas.has(carga.id_marca) &&
        dealersHabilitados.has(carga.id_dealer)
    );

    const tiposCarga = {
      VENTAS: setVentas,
      OTS: setOts,
      REPUESTOS: setRepuestos,
      "STOCK REPUESTOS": setStockRepuestos,
    };

    Object.entries(tiposCarga).forEach(([tipo, action]) => {
      dispatch(action(data.filter((carga) => carga.tipo_carga === tipo)));
    });
  } catch (error) {
    console.error("Error en fetchCargas: ", error);
    dispatch(
      hasError({ message: error.message || "Error desconocido en fetchCargas" })
    );
  } finally {
    dispatch(finishLoading());
  }
};

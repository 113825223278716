import React, { useState } from "react";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  deleteAllNotifications,
} from "../../redux/slices/notificationsNormalizada";
import { List, Typography, Tooltip, Modal } from "antd";
import {
  CloseOutlined,
  CheckOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const NotificationsNormalize = ({
  notifications,
  user,
  onViewAllNotifications,
}) => {
  const [backgroundColor, setBackgroundColor] = useState("#0866FF");
  const [isClicked, setIsClicked] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const pageTable = useSelector(
    (state) => state.notificationsNormalizada.notificationsTable.page || 1
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickDelete = (id, pageTable) => {
    dispatch(deleteNotification(id, user.id, pageTable));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = (id, pageTable) => {
    dispatch(deleteAllNotifications(id, pageTable));
    setIsModalVisible(false);
  };

  const handleClickReadAll = (id, pageTable) => {
    dispatch(markAllNotificationsAsRead(id, pageTable));
    setBackgroundColor("FFFFFF");
  };

  const handleClickRead = (id, userId, pageTable) => {
    setIsClicked(true);
    dispatch(markNotificationAsRead(id, userId, pageTable));
    setTimeout(() => setIsClicked(false), 400);
  };
  const loading = useSelector(
    (state) => state.notificationsNormalizada.loading
  );

  const error = useSelector((state) => state.notificationsNormalizada.error);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd/MM/yyyy HH:mm");
  };

  const handleViewAllNotifications = () => {
    onViewAllNotifications();
    navigate("/all-notifications");
  };

  const modalTitle = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ExclamationCircleOutlined style={{ color: "red", marginRight: "8px" }} />
      <span>Confirmar eliminación</span>
    </div>
  );

  return (
    <div
      style={{
        width: "450px",
        maxHeight: "800px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <div style={{ flexGrow: 1, marginLeft: "39%" }}>
          <Typography.Title style={{ margin: "0px" }} level={5}>
            Notificaciones
          </Typography.Title>
        </div>
        {notifications.filter((element) => element.is_read === 0).length >
          0 && (
          <Tooltip
            title="Leer todo"
            placement="topLeft"
            style={{ marginRight: "10px" }}
          >
            {!isModalVisible && (
              <CheckOutlined
                onClick={() => handleClickReadAll(user.id)}
                tooltip
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              />
            )}
          </Tooltip>
        )}
        {notifications.length > 0 && !isModalVisible && (
          <Tooltip
            title="Eliminar todo"
            placement="topLeft"
            style={{ marginRight: "20px" }}
          >
            <DeleteFilled
              onClick={showModal}
              tooltip
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginRight: "20px",
              }}
            />
          </Tooltip>
        )}
      </div>
      <Modal
        title={modalTitle}
        okText="Sí, eliminar"
        cancelText="Cancelar"
        visible={isModalVisible}
        onOk={() => handleOk(user.id, pageTable)}
        onCancel={handleCancel}
        okButtonProps={{ danger: true }}
        maskClosable={true}
        closable={true}
        zIndex={1050}
      >
        <p>¿Estás seguro de que quieres eliminar todas las notificaciones?</p>
      </Modal>
      {loading ? (
        <p>Cargando...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div style={{ width: "100%" }}>
          <List
            dataSource={notifications}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    marginRight: "20px",
                  }}
                >
                  <div
                    style={{
                      flex: 1,
                      paddingRight: "40px",
                      cursor: `${
                        item.is_read === 0 && !isClicked ? "pointer" : "none"
                      }`,
                      pointerEvents: `${
                        item.is_read === 0 && !isClicked ? "auto" : "none"
                      }`,
                    }}
                    onClick={() => handleClickRead(item.id, user.id, pageTable)}
                  >
                    {item.message}
                  </div>
                  <div
                    style={{
                      flex: 1,
                      padding: "0px",
                      cursor: `${
                        item.is_read === 0 && !isClicked ? "pointer" : "none"
                      }`,
                      pointerEvents: `${
                        item.is_read === 0 && !isClicked ? "auto" : "none"
                      }`,
                    }}
                    onClick={() => handleClickRead(item.id, user.id, pageTable)}
                  >
                    {formatDate(item.created_at)}
                  </div>
                  {item.is_read === 0 ? (
                    <div
                      className="circle"
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "100%",
                        backgroundColor,
                        marginRight: "10px",
                      }}
                    ></div>
                  ) : null}
                  <Tooltip title="Eliminar" placement="bottom">
                    <CloseOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickDelete(item.id, pageTable)}
                    />
                  </Tooltip>
                </div>
              </List.Item>
            )}
          />
        </div>
      )}
      {notifications.length > 0 && (
        <a onClick={handleViewAllNotifications}>... Todas las Notificaciones</a>
      )}
    </div>
  );
};
